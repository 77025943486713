import React, { useCallback, useState } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useDispatch } from 'react-redux';

import { FooterNavigation } from '../../components/footer-navigation.component';
import { StepsCompleted, useRegistration } from '../../hooks/use-registration.hook';
import { saveConsent } from '../../services/registrations.service';
import { setRegistration } from '../../states/registrations.slice';
import { RegisterLayout } from '../../components/register-layout.component';

export default function Index() {
    const { navigate, t } = useI18next();
    const dispatch = useDispatch();

    const registration = useRegistration({ currentStep: StepsCompleted.Pcr.Consent });;

    const [ signature, setSignature ] = useState('N/A');
    const [ errors, setErrors ] = useState(null);

    // @Todo: Remove, and remove signature from backend
    const onSubmitConsent = useCallback(() => {
        saveConsent(signature, registration.id).then(response => {
          dispatch(setRegistration(response.data.registration))
          navigate('/register/symptoms-and-history');
        }).catch(e => setErrors(e.response.data.message));
      }, [ signature, registration ]);
    
    return <RegisterLayout className="create-account narrow-nav" backLocation="/register/scan">
        <div className="section section--consent animateIn">
            <h1 className="t-h2 t-grey9 consent__heading">{ t('Test kit agreement') }</h1>
            <p className="t-sans t-p1 t-grey7">
            本検査は、採取検体をリアルタイムPCR法により新型コロナウイルスに特徴的なRNA配列が検出されるかどうかを判定するものです。<br/>
            検査結果は送付された採取検体に対しての検査結果となります。今回採取した検体の結果が陰性であっても、新型コロナウイルスに感染していないことを保証するものではありません。検査結果が陰性でも、発熱、倦怠感、咳、鼻水、呼吸困難、味覚障害などの症状がある場合や、続く場合は、必ず病院を受診してください。<br/>
            発生届対象者の検査結果が陽性の場合、連携医療機関（豊泉家クリニック桃山台：06-6873-6633）より電話診療させていただきます（発生届対象者外の方については、各都道府県のホームページを確認し陽性者登録を行ってください）。電話診療に際して、診療費用（保険診療）とシステム使用料1,100円（税込）が掛かる事に同意いただく必要があります（費用の請求書は、追って送付します）。診療後、診断結果を元に居住地の保健所に「新型コロナウイルス感染症　発生届」の届出をさせていただきます。 また、陽性と認められた場合は、感染症法に基づき保健所の指示に従ってください。検査結果に伴うその後のお客様の生活や行動については、当法人は一切関与することはございません。<br/>
            私は新型コロナウイルス感染症のPCR検査を受けるにあたり、上記の事項について説明を受け、内容を理解いたしましたので、本検査を受けることに同意します。また、採取についての虚偽の申告はしません。同封されている検体は、私自身のもので間違いありません。
            </p>
            
        </div>
        <FooterNavigation 
            steps={5} 
            activeStep={3} 
            label={t('Agree and next')} 
            onClick={onSubmitConsent}
        />
    </RegisterLayout>;
}